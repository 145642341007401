<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="capture-view-area data-list-container">
    <div class="capture-header mb-base">
      <div class="text-sm">
        <a :href="item.url | urlPath" target="_blank">
          <vs-icon icon-pack="feather" icon="icon-link" />
          {{ item.url | urlPath }}
        </a>
        <p>
          <vs-icon icon-pack="feather" icon="icon-clock" />
          {{ item.dateCaptured | moment }}
        </p>
      </div>
      <div class="block" v-if="imageDiffPercetage">
        <div class="flex">
          <p class="text-black text-right">
            <strong>{{ imageDiffPercetage }}%</strong><span class="text-xs"> Change detected<br />for {{ currentScreenSize }} viewport</span>
          </p>
        </div>
        <vs-progress class="min-w-32" height="10" :percent="imageDiffPercetage" :color="changeColor(imageDiffPercetage)"></vs-progress>
      </div>
    </div>
    <LoaderCaptureCompare v-if="!loaded"></LoaderCaptureCompare>
    <div class="capture-body" v-show="loaded">
      <div class="vx-row -mx-2 flex-nowrap">
        <div class="vx-col px-2 w-16 pt-20">
          <vs-tooltip position="right" v-for="(size, i) in viewports || []" :key="i" :text="size">
            <vs-avatar
              size="medium"
              :color="size === currentScreenSize ? 'primary' : 'grey'"
              icon-pack="feather"
              :icon="getIconBySize(size)"
              @click="currentScreenSize = size"
            />
            <span></span>
          </vs-tooltip>
        </div>
        <div class="vx-col px-2 flex-1">
          <ul class="flex mb-base">
            <li>
              <vs-button
                type="line"
                icon-pack="feather"
                icon="icon-image"
                @click="activeTab = 'diff'"
                :class="{ 'vs-button-line--active': activeTab === 'diff' }"
                >Diff</vs-button
              >
            </li>
            <li class="ml-2">
              <vs-button
                type="line"
                icon-pack="feather"
                icon="icon-tag"
                @click="activeTab = 'split'"
                :class="{ 'vs-button-line--active': activeTab === 'split' }"
                >Split</vs-button
              >
            </li>
          </ul>

          <div class="vx-tabs">
            <div class="vx-tab__content" v-show="activeTab === 'diff'">
              <div class="vx-row -mx-3 w-full">
                <div class="vx-col flex-1 px-3">
                  <div class="mb-2 flex justify-between items-center">
                    <p class="text-xs">
                      <span class="font-semibold uppercase">Capture 1:</span>
                      <vs-icon icon-pack="feather" icon="icon-clock" class="text-xs ml-2 mr-1" />{{ item.dateCaptured | moment }}
                    </p>
                    <vs-button radius @click="openComparisonHeatMap(item)" color="primary" icon="refresh" class="btn-viewcompare" />
                  </div>
                  <div class="relative">
                    <div v-show="!base64a" class="con-vs-alert con-vs-alert-warning"><div class="vs-alert">No Screenshot</div></div>
                    <img
                      ref="capture1"
                      :src="base64a"
                      class="max-w-full cursor-pointer border border-solid border-grey-light"
                      :class="base64a ? 'block' : 'hidden'"
                      @click="openCaptureFullscreen(item, base64a)"
                      crossorigin="anonymous"
                    />
                  </div>
                </div>
                <div class="vx-col flex-1 px-3">
                  <div class="mb-2 flex justify-between items-center">
                    <p class="text-xs">
                      <span class="font-semibold uppercase">Capture 2:</span>
                      <vs-icon icon-pack="feather" icon="icon-clock" class="text-xs ml-2 mr-1" />
                      {{ comparing.dateCaptured | moment }}
                    </p>
                    <vs-button radius @click="openComparisonHeatMap(comparing)" color="primary" icon="refresh" class="btn-viewcompare" />
                  </div>
                  <div class="relative">
                    <div v-show="!base64b" class="con-vs-alert con-vs-alert-warning"><div class="vs-alert">No Screenshot</div></div>
                    <img
                      ref="capture2"
                      :src="base64b"
                      class="block max-w-full cursor-pointer border border-solid border-grey-light"
                      :class="base64b ? 'block' : 'hidden'"
                      @click="openCaptureFullscreen(comparing, base64b)"
                      crossorigin="anonymous"
                    />
                  </div>
                </div>
                <div class="vx-col flex-1 px-3">
                  <div class="mb-2 flex justify-between items-center">
                    <p class="text-xs">
                      <span class="font-semibold uppercase">The Difference</span>
                    </p>
                    <vs-button radius color="primary" icon="refresh" class="btn-viewcompare invisible" />
                  </div>
                  <div class="relative">
                    <img
                      :src="imageDiff"
                      class="block max-w-full cursor-pointer border border-solid border-grey-light"
                      @click="openCaptureFullscreen(null, imageDiff)"
                      crossorigin="anonymous"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-tab__content" v-show="activeTab === 'split'">
              <div v-if="!base64a || !base64b" class="con-vs-alert con-vs-alert-warning"><div class="vs-alert">Incomplete captures</div></div>
              <VueCompareImage v-else :style="{ 'max-width': selectedScreenMaxWidth }" :leftImage="base64a" :rightImage="base64b" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import VueCompareImage from 'vue-compare-image';
import compare from 'resemblejs/compareImages';
import LoaderCaptureCompare from '@/views/captures/components/loader/CaptureCompare';

export default {
  components: {
    VueCompareImage,
    LoaderCaptureCompare
  },
  data() {
    return {
      baseUrl: window.location.origin,
      base64a: '',
      base64b: '',
      imageDiff: '',
      imageDiffPercetage: 0,
      loaded: false,
      currentScreenSize: null,
      activeTab: 'diff'
      // result: {}
    };
  },
  computed: {
    ...mapState('captures', ['item', 'comparing']),
    ...mapState({
      isSmallerScreen: state => state.windowWidth < 768
    }),
    viewports() {
      if (!this.loaded) return [];
      const viewports = new Array();
      const item = this.item;
      const comparing = this.comparing;

      function concatScreenShots(screenshots) {
        for (let i = 0; i < screenshots.length; i++) {
          const item = screenshots[i];
          const viewPort = item.viewPort;
          const exists = viewports.find(s => s.includes(`${viewPort.width}x`));
          if (!exists) {
            viewports.push(`${viewPort.width}x${viewPort.height}`);
          } else {
            const [w, h] = exists.split('x');
            if (Number(h) !== viewPort.height) {
              const indexOfMatchingWidth = viewports.indexOf(exists);
              viewports.splice(indexOfMatchingWidth, 1, `${w}x`);
            }
          }
        }
      }

      if (item && item.screenshots && item.screenshots.length > 0) concatScreenShots(item.screenshots);
      if (comparing && comparing.screenshots && comparing.screenshots.length > 0) concatScreenShots(comparing.screenshots);
      return viewports;
    },
    captureSrc() {
      return (screenshots, size) => {
        if (!this.currentScreenSize || !screenshots) return null;
        for (let i = 0; i < screenshots.length; i++) {
          const item = screenshots[i];
          const width = item.viewPort.width;
          const [w] = this.currentScreenSize.split('x');
          if (width === Number(w)) return !size ? item.screenShotPath : item.image[size];
        }
        return null;
      };
    },
    selectedScreenMaxWidth() {
      if (!this.currentScreenSize) return '100%';
      return this.currentScreenSize.split('x')[0] + 'px';
    }
  },
  methods: {
    openComparisonHeatMap(data) {
      this.$vxPopup.open({
        attrs: {
          title: 'Select Comparison'
        },
        config: {
          component: 'CalendarHeatMap',
          data
        }
      });
    },
    changeColor(value) {
      if (value >= 25) {
        return 'danger';
      } else if (value >= 15) {
        return 'warning';
      } else if (value >= 0) {
        return 'success';
      } else {
        return 'primary';
      }
    },
    async getImageDifference() {
      function getBase64FromImageUrl(url) {
        if (!url) return null;
        return new Promise(resolve => {
          var img = new Image();
          img.crossOrigin = 'Anonymous';
          // img.setAttribute('crossOrigin', 'Anonymous');

          img.onload = function() {
            var canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;

            var ctx = canvas.getContext('2d');
            ctx.drawImage(this, 0, 0);
            resolve(canvas.toDataURL('image/png'));
          };

          img.src = url;
        });
      }

      const image1 = this.captureSrc(this.item.screenshots);
      const image2 = this.captureSrc(this.comparing.screenshots);

      this.base64a = await getBase64FromImageUrl(image1);
      this.base64b = await getBase64FromImageUrl(image2);

      if (!image1 || !image2) return (this.imageDiff = image1 || image2);
      const options = {
        output: {
          errorColor: {
            red: 0,
            green: 255,
            blue: 255
          },
          errorType: 'movement',
          largeImageThreshold: 1200,
          useCrossOrigin: true,
          outputDiff: true
        },
        scaleToSameSize: true,
        ignore: 'less'
      };

      const data = await compare(this.base64a, this.base64b, options);

      this.imageDiffPercetage = Number(data.misMatchPercentage);
      this.imageDiff = data.getImageDataUrl();
      return;
    },
    openCaptureFullscreen(item, src) {
      try {
        this.$vxPopup.open({
          attrs: {
            title: item ? `Captured: ${this.$options.filters.moment(item.dateCaptured)}` : `The Difference`,
            fullscreen: true
          },
          config: {
            component: 'BlockImage',
            src: src || this.captureSrc(item.screenshots)
          }
        });
      } catch (error) {
        this.$vs.notify({
          color: 'warning',
          title: 'Sorry',
          text: 'Image rendering interrupted, please try again later.'
        });
      }
    },
    getIconBySize(size) {
      const [w] = size.split('x');
      return w > 768 ? 'icon-monitor' : 'icon-smartphone';
    }
  },
  beforeCreate() {
    this.loaded = false;
  },
  created() {},
  mounted() {
    this.loaded = true;
    this.$nextTick(() => {
      const viewports = this.viewports;
      if (viewports && viewports.length > 0) this.currentScreenSize = viewports[0];
    });
  },
  watch: {
    currentScreenSize(value) {
      this.$clearTooltip();
      this.$nextTick(async () => {
        this.loaded = false;
        await this.getImageDifference();
        this.loaded = true;
      });
    }
  }
};
</script>

<style lang="scss">
.btn-viewcompare {
  &.includeIconOnly {
    padding: 0 !important;
    width: 28px !important;
    height: 28px !important;
  }
}
</style>
